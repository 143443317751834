import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import ReviewCardProf from "../components/review-card-prof";
import ReviewCard from "../components/review-card";

const TestimonialsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Testimonial-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpTestimonial(
				filter: { testimonialFeilds: { professional: { ne: true } } }
			) {
				nodes {
					testimonialFeilds {
						name
						professional
						review
						jobTitle
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}
			profReviews: allWpTestimonial(
				filter: { testimonialFeilds: { professional: { eq: true } } }
			) {
				nodes {
					testimonialFeilds {
						name
						professional
						review
						jobTitle
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Testimonials",
				item: {
					url: `${siteUrl}/testimonials`,
					id: `${siteUrl}/testimonials`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Testimonials | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/testimonials`,
					title: "Testimonials | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > Testimonials"
						title="Testimonials"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-3 pb-lg-7">
								<h2 className="text-dark-bg pb-4">Professional testimonials</h2>
								{/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="gx-5 pt-5">
							{data.profReviews.nodes.map((review) => (
								<Col className="mb-5 mb-xl-0" xs={12} md={6} xl={3}>
									<ReviewCardProf review={review} />
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="text-center pb-3 pb-lg-7">
								<h2 className="text-dark-bg pb-4">
									Therapy patient testimonials
								</h2>
								{/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="gx-5 pt-5">
							{data.allWpTestimonial.nodes.map((review) => (
								<Col className="mb-6 " xs={12} md={6} xl={3}>
									<ReviewCard review={review} />
								</Col>
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default TestimonialsPage;
