import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage } from "gatsby-plugin-image";

const ReviewCardProf = ({ review }) => {
	var trimmedString = review.testimonialFeilds.review.substr(0, 140);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.testimonialFeilds.review);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		console.log("clicked");
		if (!expanded) {
			setReviewBody(review.testimonialFeilds.review);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Card
			style={{
				boxShadow: "0px 0px 23px #0A2E7214",
				borderRadius: "10px",
				minHeight: "344px",
			}}
			className="border-0 review-card px-2 position-relative mx-0 feature-box"
		>
			<div
				style={{ maxWidth: "50px", height: "50px", borderRadius: "100%" }}
				className="position-absolute top-0 translate-middle-y start-10"
			>
				<GatsbyImage
					style={{ maxWidth: "50px", height: "50px", borderRadius: "100%" }}
					image={
						review.testimonialFeilds.image.localFile.childImageSharp
							.gatsbyImageData
					}
					alt={review.testimonialFeilds.image.altText}
				/>
			</div>
			<Card.Header
				style={{ borderRadius: "10px" }}
				className="bg-white pt-5 border-0"
			>
				<Row className="align-items-center">
					<Col xs={9}>
						<Card.Title className="text-dark-bg mb-0 mb-md-1 mb-lg-0">
							{review.testimonialFeilds.name}
						</Card.Title>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body className="mt-0 pt-0">
				<Row>
					<Col>
						<Card.Text className="pt-0 mt-0">
							<p style={{ fontSize: "80%" }} className="text-secondary">
								{review.testimonialFeilds.jobTitle}
							</p>
							{review.testimonialFeilds.review.length <= 140 && (
								<p>{review.testimonialFeilds.review}</p>
							)}
							{review.testimonialFeilds.review.length > 140 && (
								<p>{reviewBody}</p>
							)}
						</Card.Text>
					</Col>
				</Row>
				{review.testimonialFeilds.review.length > 140 && (
					<Row>
						<Col>
							<div className="mt-4" style={{ height: "10px" }}></div>
							<p
								className=" ubuntu-medium pointer link-primary position-absolute bottom-0 pb-3 "
								onClick={handleClick}
							>
								Read more
							</p>
						</Col>
					</Row>
				)}
			</Card.Body>
		</Card>
	);
};

export default ReviewCardProf;
